<template>
  <v-app fill-height class="w-100 grey lighten-4">
    <main class="w-100 my-3 fill-height mx-auto">
      <v-container fluid fill-height>
        <section class="w-100 d-flex align-items-center justify-content-center fill-height">
          <div class="col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height">
            <div class="mt-5">
              <span class="f24 font-weight-bold">{{
                  $t("Complete your profile")
              }}</span>
            </div>

            <div class="mt-2">
              <span class="f16 grey--text text--darken-1 font-weight-normal">
                {{
                    $t("Please enter your information correctly in the field below")
                }}
              </span>
            </div>

            <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mt-2">

              <div class="position-relative mx-auto">
<!--                <croppa class="text-center mt-2 pointer rounded-circle overflow-hidden" v-model="profilePic"-->
<!--                  :width="150" :height="150" :prevent-white-space="true" @init="onInit" placeholder=""-->
<!--                  canvas-color="transparent" @new-image-drawn="handleNewImage" @image-remove="handleImageRemove">-->

<!--                  <img slot="placeholder"-->
<!--                    :src="doctor.pic != null ? doctor.pic : require('@/assets/images/profile-circle.png')" width="150"-->
<!--                    height="150" />-->

<!--                </croppa>-->
                <croppa
                    class="text-center mt-2 pointer rounded-circle overflow-hidden"
                    v-model="profilePic"
                    :width="150"
                    :height="150"
                    :quality="4"
                    :prevent-white-space="true"
                    @init="onInit"
                    placeholder=""
                    canvas-color="transparent"
                    @image-remove="handleNewImage"
                    @file-choose="handleNewImage"
                    :show-remove-button="true"
                    remove-button-color="red"
                    :remove-button-size="24"
                    crossOrigin="anonymous"
                >

                  <img slot="placeholder"
                       crossorigin="anonymous"
                       :src="doctor.pic != null ? getProfilePic :
                        require('@/assets/images/profile-circle.png')" width="150"
                       height="150" />

                </croppa>
                <v-icon small class="avatar-select position-absolute rounded-pill
                primary white--text text-center p-2" @click="profilePic.chooseFile()">
                  mdi-pencil
                </v-icon>
              </div>

              <div class="d-flex flex-column justify-content-between mt-auto">

                <v-text-field outlined v-model="username" type="text" :label="$t('Enter your username')"
                  placeholder="مثال: DrAliAhmadi" class="text-center mt-4" :rules="usernameRules" required />

                <div class="d-flex flex-row justify-content-between mt-1">
                  <v-text-field outlined v-model="firstName" type="text" :label="$t('Enter your name')"
                    class="text-center ms-0 me-1" :rules="[(v) => !!v]" required />

                  <v-text-field outlined v-model="lastName" type="text" :label="$t('Enter your last name')"
                    class="text-center me-0 ms-1" :rules="[(v) => !!v]" required />
                </div>

                <v-text-field outlined v-model="mobileNumber" type="text"
                              :label="$t('Mobile Number ( Private )')" readonly
                  class="text-center number-field" />


                <v-text-field outlined v-model="phoneNumber" type="text"
                              :label="$t('Phone Number ( for Clinic )')"
                  class="text-center number-field" />


                <v-textarea outlined v-model="bio" type="text" :label="$t('Enter your bio')" counter="255"
                  :rules="bioRules" required />

                <v-autocomplete :label="$t('Choose your gender')" v-model="gender" outlined required small-chips
                  :no-data-text="$t('No data')" :items="genderItems" item-text="text" item-value="value"
                  :rules="[(v) => !!v]">
                </v-autocomplete>
                <v-autocomplete :label="$t('Choose your city')" v-model="chooseCity" :items="cities" item-text="name"
                  item-value="name" outlined required small-chips :no-data-text="$t('No data')" :rules="[(v) => !!v]">
                </v-autocomplete>

                <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="educations" :items="educationItems"
                  chips small-chips item-text="value" item-value="id" :label="$t('Educational qualifications')"
                  :loading="isLoadingEducation" return-object outlined multiple>
                </v-autocomplete>

                <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="specialties" :items="specialtyItems"
                  chips small-chips item-text="value" item-value="id" :label="$t('Work specialties')"
                  :loading="isLoadingSpecialty" return-object outlined multiple>
                </v-autocomplete>

                <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="activities" :items="activityItems"
                  chips small-chips item-text="value" item-value="id" :label="$t('Field of activity')"
                  :loading="isLoadingActivity" return-object outlined multiple>
                </v-autocomplete>

                <v-text-field outlined v-model="clinicName" :label="$t('Clinic name')" readonly class="text-center" />


                <div class="mt-1">
                  <div class="mt-2 d-flex flex-row align-items-stretch">
                    <v-btn color="primary" large class="col-8 flex-fill mx-2 13"
                           @click="validate()" :loading="submit_loading"
                           :disabled="submit_loading">
                      {{ $t('Register') }}
                    </v-btn>

                    <v-btn color="red" large class="col-4 text-white flex-fill mx-2 f13" @click="backToAuth()"
                           :loading="submit_loading"
                           :disabled="submit_loading">
                      {{ $t('Canceled') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </section>
      </v-container>
    </main>
  </v-app>
</template>
<script>
import cityDATA from "@/assets/city.json";
import axios from "@/http/httpApplication.js";
import apiService from "@/http/ApiService.js"

export default {
  data() {
    return {
      submit_loading: false,

      valid: false,
      progress: 0,

      isLoadingEducation: true,
      isLoadingSpecialty: true,
      isLoadingActivity: true,

      picChanged: false,
      uploadedPicUrl: "",
      uploadedThumbnailPicUrl: "",
      profilePic: {},

      cities: cityDATA,
      firstName: "",
      lastName: "",
      username: "",
      mobileNumber: this.$store.getters.mobileNumber,
      phoneNumber: '',
      bio: "",
      chooseCity: "",
      gender: "",
      clinicName: "",
      genderItems: [
        { text: this.$t("Man"), value: "MAN" },
        { text: this.$t("Woman"), value: "WOMAN" },
        { text: this.$t("Other"), value: "OTHER" },
      ],

      bioRules: [
        v => !!v,
        v => (v && v.length <= 255) || this.$t("The maximum length is 255 characters")
      ],
      // usernameRules: [
      //   v => !!v,
      //   v => (v || '').indexOf(' ') < 0 || this.$t("No spaces are allowed")
      // ],

      educationItems: [],
      educations: [],
      specialtyItems: [],
      specialties: [],
      activityItems: [],
      activities: [],

      doctor: {},

    };
  },
  computed: {
    usernameRules() {
      return [
        v => !!v || this.$t("Enter your username"),
        v => (v || '').indexOf(' ') < 0 || this.$t("No spaces are allowed"),
        () => this.isAlphanumericValid() || this.$t('Only English letters and numbers are allowed'),
      ];
    },
    getProfilePic() {
      return this.doctor.pic.replace("http://", "https://")
    }
  },
  methods: {
    isAlphanumericValid() {
      return /^[a-zA-Z0-9]+$/.test(this.username);
    },
    onInit() {
      this.profilePic.addClipPlugin(function (ctx, x, y, w, h) {
        /*
         * ctx: canvas context
         * x: start point (top-left corner) x coordination
         * y: start point (top-left corner) y coordination
         * w: croppa width
         * h: croppa height
        */
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    handleNewImage() {
      this.picChanged = true;
    },
    handleImageRemove() {
      this.picChanged = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.UpdateDoctor();
      }
    },
    getDoctorInfoByMyMobile() {
      apiService.getDoctorInfoByMyMobile()
        .then((response) => {
          this.doctor = response.data;
          // this.profilePic = this.doctor.pic
          this.firstName = this.doctor.firstname
          this.lastName = this.doctor.lastname
          this.username = this.doctor.username
          this.mobileNumber = this.doctor.mobile
          this.phoneNumber = this.doctor.phone
          this.bio = this.doctor.bio
          this.chooseCity = this.doctor.city
          this.gender = this.doctor.gender
          this.clinicName = this.doctor.clinic.name
          this.activities = this.doctor.activities
          this.specialties = this.doctor.specialties
          this.educations = this.doctor.educations
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEducations() {
      apiService.getEducations()
        .then((response) => {
          this.educationItems = response.data;
          this.isLoadingEducation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSpeciality() {
      apiService.getSpeciality()
        .then((response) => {
          this.specialtyItems = response.data;
          this.isLoadingSpecialty = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivities() {
      apiService.getActivities()
        .then((response) => {
          this.activityItems = response.data;
          this.isLoadingActivity = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateDoctor() {
      if (this.picChanged) {
        this.uploadPic();
      }
      else {
        this.submitForm();
      }
    },
    submitForm() {
      this.submit_loading = true;
      axios({
        url: `${process.env.VUE_APP_API_URL}/doctor/complete-signup`,
        method: "post",
        data: {
          version: this.doctor.version,
          deleted: this.doctor.deleted,
          id: this.doctor.id,
          answerCount: this.doctor.answerCount,
          followerCount: this.doctor.followerCount,
          postCount: this.doctor.postCount,
          score: this.doctor.score,
          firstname: this.firstName,
          lastname: this.lastName,
          username: this.username,
          bio: this.bio,
          mobile: this.mobileNumber,
          gender: this.gender,
          city: this.chooseCity,
          specialties: this.specialties,
          educations: this.educations,
          activities: this.activities,
          pic: this.picChanged ? this.uploadedPicUrl : this.doctor.pic, //TODO
          thumbnailUrl: this.picChanged ? this.uploadedThumbnailPicUrl : this.doctor.thumbnailUrl, //TODO
          roleCode: this.doctor.roleCode,
          address: this.doctor.address,
          consultantAmount: this.doctor.consultantAmount,
          contactDescription: this.doctor.contactDescription,
          enabled: this.doctor.enabled,
          freeCount: this.doctor.freeCount,
          isClinicNumber: this.doctor.isClinicNumber,
          lat: this.doctor.lat,
          lon: this.doctor.lon,
          notificationToken: this.doctor.notificationToken,
          phone: this.doctor.phone || this.phoneNumber,
          registered: this.doctor.registered,
          certifiedBy: this.doctor.certifiedBy,
          emergencyCallStatus: this.doctor.emergencyCallStatus,
          onlineCallStatus: this.doctor.onlineCallStatus,
          phoneCallStatus: this.doctor.phoneCallStatus,
          consultantPaymentType: this.doctor.consultantPaymentType,
        },
      })
        .then((response) => {
          this.submit_loading = false;
          if (response.status == 200) {
            apiService.saveFcmTokenDoctor(this.$store.getters.firebaseToken);
            this.$store.commit("update_authenticated", true);
            this.$router.push("/s/home");
          }
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    uploadPic() {
      this.submit_loading = true;
      if (this.profilePic.hasImage()) {
        // this.profilePic = this.profilePic.crossOrigin="anonymous"
        this.profilePic.generateBlob((blob) => {
              apiService.upload("PROFILE", blob, Date.now() + ".png", (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
              })
                  .then((response) => {
                    this.submit_loading = false;
                    this.uploadedPicUrl = response.data.originalFileDownloadUrl;
                    this.uploadedThumbnailPicUrl = response.data.thumbnailPicDownloadUrl;
                    this.submitForm();
                  })
                  .catch(() => {
                    this.submit_loading = false;
                    this.$swal({
                      icon: "warning",
                      text: this.$t('Could not upload the file!'),
                      confirmButtonText: this.$t("OK"),
                    });
                  });
            },
            'image/png',
            1
        ); // 85% compressed jpeg file
      }
      else {
        console.log("NO IMAGE SELECTED");
        this.$swal({
          icon: "warning",
          text: this.$t('No image selected. Please select an image'),
          confirmButtonText: this.$t("OK"),
        });
        this.submit_loading = false;
      }
    },
    backToAuth() {
      this.$store.dispatch("logout");
      this.$router.push("/auth")
    },
  },
  created() {
    // this.$store.dispatch("fakeLogin");

    this.getDoctorInfoByMyMobile();
    this.getEducations();
    this.getSpeciality();
    this.getActivities();
    //   if (this.$store.getters.isLoggedIn && this.$store.getters.authenticated) {
    //     if (this.$store.getters.clientType == "CLIENT") {
    //       this.$router.push("/c/home");
    //     } else if (this.$store.getters.clientType == "DOCTOR") {
    //       this.$router.push("/s/home");
    //     }
    //   }
  },
};
</script>
<style scoped>
.croppa-container {
  background-color: transparent !important;
}
</style>
